import React, { useEffect, useState } from 'react';
import { ChakraProvider, extendTheme, useToast } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoutes, { ProtectedRouteLogin } from './config/ProtectedRoutes';
import Signup from './pages/Signup';
import Auth from './pages/Auth';
import Homepage from './pages/Homepage';
import Contact from './pages/Contact';
import ProductDetails from './pages/Product/ProductDetails';
import Cart from './pages/Cart';
import Orders from './pages/Order/Orders';
import Account from './pages/Account';
import Checkout from './pages/Checkout';
import { trimTitle } from './config/functions';
import Shop from './pages/Shop';
import OrderDetails from './pages/Order/OrderDetails';
import Forgot from './pages/Forgot-password';
import PasswordChange from './pages/PasswordChange';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import PasswordReset from './pages/PasswordReset';
import AssistancePopover from './pages/AssistancePopover';

// EXTENDED COLORS
const colors = {
  brand: {
    primary: '#326EBE',
    hoverPrimary: '#235EAC',
    primaryLight: '#93A1EF',
  },
  // brand: {
  //   primary: '#EE3737',
  //   hoverPrimary: '#EE6363',
  // },
};

const theme = extendTheme({
  colors,
});

function App() {
  const [currency, setCurrency] = useState('BIF');
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve cart items from local storage on component mount
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  const handleCurrency = val => {
    setCurrency(val);
  };

  const toast = useToast();

  const addToCart = async newItem => {
    // Check if cart is empty or if the currency matches the currency of the first item in the cart
    if (cartItems.length === 0 || cartItems[0].currency === newItem.currency) {
      const examplePromise = new Promise((resolve, reject) => {
        // Simulate an asynchronous action (e.g., API call)
        setTimeout(() => {
          // Resolve the promise with a success status code
          resolve(200);
        }, 100);
      });

      try {
        // Display the loading toast until the promise is either resolved or rejected
        await toast.promise(examplePromise, {
          success: {
            title: trimTitle(newItem.name),
            description: 'Successfully added to cart',
            duration: 500,
            colorScheme: 'blue',
          },
          loading: {
            title: 'Adding to Cart',
            description: 'Please wait',
          },
        });

        // Check the response to ensure it was successful before updating the cart
        // if (response === 200) {
        const existingItem = cartItems.find(item => item.id === newItem.id);

        if (existingItem) {
          const updatedCart = cartItems.map(item =>
            item.id === newItem.id ? { ...item, qty: item.qty + 1 } : item
          );
          setCartItems(updatedCart);
        } else {
          setCartItems([...cartItems, { ...newItem, qty: 1 }]);
        }
        // }
      } catch (error) {
        // Handle any errors that occurred during the promise execution
        console.error('Error adding to cart:', error);
      }
    } else {
      // Handle case where currencies do not match (e.g., display an error message)
      toast({
        title: 'Failed to add to cart',
        description:
          'Items with different currencies cannot be added to the same cart.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeItem = itemToRemove => {
    const updatedCart = cartItems.filter(item => item.id !== itemToRemove.id);
    setCartItems(updatedCart);
  };

  const updateCartItem = (updatedItem, newQty) => {
    const updatedCart = cartItems.map(item =>
      item.id === updatedItem.id ? { ...item, qty: newQty } : item
    );
    setCartItems(updatedCart);
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Update local storage whenever cart items change
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Navbar currency={currency} handleCurrency={handleCurrency} />
        <br />
        <br />
        <br />
        <AssistancePopover />
        <Routes>
          {/* FREE ROUTES */}
          <Route
            exact
            path="/"
            element={
              <Homepage
                currency={currency}
                cartItems={cartItems}
                clearCart={clearCart}
                addToCart={addToCart}
                removeItem={removeItem}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            }
          />
          <Route
            exact
            path="/cart"
            element={
              <Cart
                cartItems={cartItems}
                removeItem={removeItem}
                updateCartItem={updateCartItem}
              />
            }
          />
          <Route
            exact
            path="/checkout"
            element={<Checkout cartItems={cartItems} clearCart={clearCart} />}
          />
          <Route
            exact
            path="/shop"
            element={<Shop addToCart={addToCart} currency={currency} />}
          />

          <Route
            exact
            path="/products/:id"
            element={
              <ProductDetails addToCart={addToCart} currency={currency} />
            }
          />
          <Route
            exact
            path="/reset/:userId/:token"
            element={<PasswordReset />}
          />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/terms-conditions" element={<Terms />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          {/* NON-UNAUTHENTICATE ROUTES MUST */}
          <Route
            exact
            path="/signin"
            element={
              <ProtectedRouteLogin>
                <Auth />
              </ProtectedRouteLogin>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <ProtectedRouteLogin>
                <Signup />
              </ProtectedRouteLogin>
            }
          />

          <Route
            exact
            path="/forgot-password"
            element={
              <ProtectedRouteLogin>
                <Forgot />
              </ProtectedRouteLogin>
            }
          />

          {/* AUTHENTICATE ROUTES */}
          <Route element={<ProtectedRoutes />}>
            <Route exact path="/orders" element={<Orders />} />
            <Route exact path="/account" element={<Account />} />
            <Route exact path="/change-password" element={<PasswordChange />} />
            <Route exact path="/orders/:id" element={<OrderDetails />} />
          </Route>
        </Routes>

        <Footer />
      </ChakraProvider>
    </Router>
  );
}

export default App;
